
import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { HomeOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css'; // Import CSS của Ant Design

//
import HomePage from '../page/HomePage';
import SearchPage from '../page/SearchPage';
import ProfilePage from '../page/ProfilePage';

import Carousels from '../page/HomePage/component/Carousels';

const { Header, Content, Footer } = Layout;

const LayoutHome = () => {
    const [options, setOptions] = useState(1);
 
    return (
      <Layout className="layout">
        {/* Menu top */}
        <Carousels />
        {/* Nội dung trang */}
        <Content style={{ padding: '0 5px', minHeight: '70vh' }}>
          <div className="site-layout-content" style={{ minHeight: '280px' }}>
            {
              options === 1 ? <HomePage /> : options === 2 ? <SearchPage /> : <ProfilePage />
            }
            <p></p>
          </div>
        </Content>
   
        <Footer>
    
        </Footer>
      </Layout>
    );
  }

export default LayoutHome