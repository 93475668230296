import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Modal, Pagination, Spin, Alert } from 'antd';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loading from '../../component/Loading';
import TextToSpeech from './component/TextToSpeech';
import { CallApi } from '../../api/CallApi';
import './styles.css';

const ChapterPage = () => {
  const { id, chapter, index } = useParams();
  const location = useLocation();

  const indexChap = parseInt(index);


  const [postsComic, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);




  // Hàm lấy số chương từ tiêu đề
  const getChapterNumber = useCallback((title) => {
    const regex = /chuong[-\s](\d+)/i;
    const match = title.match(regex);
    return match ? parseInt(match[1], 10) : null;
  }, []);

  const chap = getChapterNumber(chapter)
  const [linkChapter, setLinkChapter] = useState(chap);


  const fetchChapterData = async () => {
    setLoading(false);
    const url = `${process.env.REACT_APP_API_URL}/chapter?url=${process.env.REACT_APP_API_URL_COMIC}/${id}/chuong-${linkChapter}/`;
    try {
      const res = await CallApi(url);
      setPosts(res);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(true);
    }
  }


  useEffect(() => {
    fetchChapterData();
  }, [linkChapter]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [linkChapter]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setVisible(scrollY <= lastScrollY || scrollY <= 50);
      setLastScrollY(scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);



  const renderContent = useMemo(() => {
    if (error) return <p>Oops! Something went wrong: {error.message}</p>;
    return (
      <div className='chapter-page'>
        <TextToSpeech text={postsComic?.content?.replace(/<[^>]*>/g, '').replace(/\s+/g, ' ').trim().replace(/[.,!?]+/g, '')} />
        <h3 style={{ textAlign: 'center' }}>{postsComic?.title}</h3>
        <div
          style={{
            opacity: loading ? 1 : 0.5, transition: 'opacity 0.3s ease',
            backgroundColor: 'white',
            padding: '20px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            lineHeight: '1.6',
            marginBottom: '20px',
          }}
          dangerouslySetInnerHTML={{ __html: postsComic?.content }}
        />
        <p style={{ textAlign: 'center' }}>METRUYEN</p>
      </div>
    );
  }, [ error, postsComic, loading]);
console.log(postsComic.title)

  return (
    <div>
      <Helmet>
        <title>{postsComic?.title}</title>
        <meta name="description" content="Mô tả ngắn gọn về trang của bạn." />
        <meta name="keywords" content="từ khóa1, từ khóa2, từ khóa3" />
      </Helmet>
      {renderContent}

      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: '10px 0',
          transition: 'transform 0.3s ease, opacity 0.3s ease',
          transform: visible ? 'translateY(0)' : 'translateY(100%)',
          opacity: visible ? 1 : 0,
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          height: '100px',
        }}
      >
        <div style={{ width: '100%' }}>
          <div style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '10px' }}>
            {postsComic?.title}
          </div>
        <div className="pagination-bar">
          <Button className="pagination-button" onClick={() => setLinkChapter(linkChapter - 1)} disabled={linkChapter === 1}>Trang trước</Button>
            <select className="pagination-select" value={linkChapter} onChange={(e) => setLinkChapter(parseInt(e.target.value))}>
              {[...Array(indexChap).keys()].map((x) => (
                <option key={x + 1} value={x + 1}>
                  Trang hiện tại: {`${x+1}/${indexChap}`}
                </option>
              ))}
            </select>
      
          <Button className="pagination-button" onClick={() => setLinkChapter(linkChapter + 1)} disabled={linkChapter ===indexChap}>Trang sau</Button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default ChapterPage;
