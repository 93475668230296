import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ListChap from './component/ListChap';
import ProductDetail from './component/ProductDetail';
import { CallApi } from '../../api/CallApi';
import { Pagination } from 'antd';
import './style.css';

const Comic = () => {
  const { id, index } = useParams();
  const location = useLocation();
  const story = location.state;

  const [postsComic, setPosts] = useState({ story: story });
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  

  // const fetchComicData = async (pageNumber) => {
  //   setLoading(true);
  //   const url = `${process.env.REACT_APP_API_URL}/truyen?url=${process.env.REACT_APP_API_URL_COMIC}/${id}/`;
  //   const params = { page: pageNumber, limit: 10 };
  //   console.log("dât")

  //   try {
  //     const res = await CallApi(url, params);
  //     setPosts(res);
  //   } catch (error) {
  //     console.error("Failed to load posts:", error);
  //     setError(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [id, page]);

  const onChange = (pageNumber) => {
    setPage(pageNumber);
  };
  function extractChapterNumber(text) {
    const match = text?.match(/\d+/); // Tìm số trong chuỗi
    return match ? parseInt(match[0], 10) : null; // Trả về số chương hoặc null nếu không tìm thấy
  }
  if (error) return <p>Oops! Something went wrong: {error.message}</p>;
  return (
    <div className="comic-page">
      <Helmet>
        <title>{`${postsComic?.story?.title}`}</title>
        <meta name="description" content={`${postsComic?.story?.title} - Chương ${index}`} />
        <meta name="keywords" content={`mê truyện, ${postsComic?.story?.title}, Chương ${index}, ${postsComic?.story?.author}, chương ${index}`} />
      </Helmet>
      <ProductDetail posts={postsComic} index={parseInt(index)} />
      <ListChap index={parseInt(index)} id={id} posts={postsComic?.story?.chapters} name={postsComic?.story?.name} total={10} />
      <div style={{
        position: 'fixed', bottom: 0, width: '100%', backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px',
      }}>
        {/* <Pagination
          showQuickJumper
          defaultPageSize={50}
          defaultCurrent={1}
          total={index}
          onChange={onChange}
        /> */}
      </div>
    </div>
  );
};

export default Comic;
