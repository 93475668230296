import React, { useEffect, useState } from 'react';
import { BookOutlined, AliwangwangOutlined, HomeOutlined } from '@ant-design/icons';

import { Avatar, Badge, Space, FloatButton } from 'antd';
import { useNavigate } from 'react-router-dom';
const MenuFload = () => {

    // Khởi tạo kho truyện từ localStorage
    const [comics, setComics] = useState(() => {
      const storedComics = localStorage.getItem('comics');
      return storedComics ? JSON.parse(storedComics) : [];
    });

     // Lấy giá trị dark mode từ localStorage hoặc mặc định là false (chế độ sáng)
    const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });
    // Cập nhật class cho body khi chế độ thay đổi và lưu vào localStorage
    useEffect(() => {
      if (darkMode) {
        document.body.classList.add('dark-mode');
        document.body.classList.remove('light-mode');
        const divElement = document.getElementById('app-container');
        const ant = document.getElementsByClassName('ant-layout');
        if (divElement) {
          divElement.classList.add('dark-mode');
          divElement.classList.remove('light-mode');
          
        }
        if (ant) {
          ant[0]?.classList.add('dark-mode');
          ant[0]?.classList.remove('light-mode');
        }
    
      } else {
        document.body.classList.add('light-mode');
        document.body.classList.remove('dark-mode');
        const divElement = document.getElementById('app-container');
        const ant = document.getElementsByClassName('ant-layout');
        if (divElement) {
          divElement.classList.add('light-mode');
          divElement.classList.remove('dark-mode');
          
        }
        if (ant) {
          ant[0]?.classList.add('light-mode');
          ant[0]?.classList.remove('dark-mode');
        }
      }
  
      localStorage.setItem('darkMode', JSON.stringify(darkMode));
    }, [darkMode]);


  
  // Cập nhật localStorage mỗi khi danh sách comics thay đổi
  useEffect(() => {
    localStorage.setItem('comics', JSON.stringify(comics));
  }, [comics]);

    const navigate = useNavigate();

    const LightRom = () => {
      setDarkMode(prevMode => !prevMode);
    }
    return(
  <>
    <FloatButton.Group
      trigger="click"
      style={{
        insetInlineEnd: 24,
        bottom: 110,
        position: 'fixed',
        zIndex: 999,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        height: 48,
        backgroundColor: '#ffffff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
        borderRadius: '50%',
      }}
      icon={<Badge offset={[5, -9]} count={comics.length}><AliwangwangOutlined /></Badge>}
    >
      <Badge count={comics.length}>
        <FloatButton onClick={() => navigate('/comic/stores')} icon={<BookOutlined />}/>
     </Badge>
     <FloatButton onClick={() => navigate('/')} icon={<HomeOutlined />} />
     {/* <FloatButton className={darkMode ? 'dark' : 'light'} onClick={LightRom} icon={<HomeOutlined />} /> */}
    </FloatButton.Group>
  </>
)};
export default MenuFload;