import React, { useEffect, useState } from 'react';



import Book from "../HomePage/component/Book";

const ComicStorePage = () => {
     // Khởi tạo kho truyện từ localStorage
    const [comics, setComics] = useState(() => {
      const storedComics = localStorage.getItem('comics');
      return storedComics ? JSON.parse(storedComics) : [];
    });
  
  // Cập nhật localStorage mỗi khi danh sách comics thay đổi
  useEffect(() => {
    localStorage.setItem('comics', JSON.stringify(comics));
  }, [comics]);
    return (
        <div>
            <h1>Comic Store Page</h1>   
            <Book posts={comics}/>
        </div>  
    )
}

export default ComicStorePage