import React from 'react';
import { Carousel, Image } from 'antd';

const Carousels = () => (
  <Carousel autoplay >
    <div style={{width: '100%'}}>
      <Image className='image-carousel' src="/images/banner1.png" />
    </div>
    <div style={{width: '100%'}}>
      <Image className='image-carousel' src="/images/banner2.png" />
    </div>
    <div style={{width: '100%'}}>
      <Image className='image-carousel' src="/images/banner3.png" />
    </div>
    <div style={{width: '100%'}}>
      <Image className='image-carousel' src="/images/banner4.png" />
    </div>
  </Carousel>
);
export default Carousels;