
import React from 'react';
import { StarTwoTone, StarFilled, 
  ClockCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import Follow from './Follow';

import images from '../../../acess/dataImage';
import { Divider, Flex, Tag } from 'antd';


const Info = ({posts, index}) => {
    return (
      <div className="product-info">
        <div className="right">
          <div className="book-card" style={{width: '128px', height:'200px'}}>
            <img  
                src={'/images/39.jpg'} 
                className="book-cover" />
            <div className="book-info">
              <h3>爱情事务</h3>
              <p style={{marginBottom: '5px', fontSize: '10px'}}>{posts?.story?.title}</p>
              <p style={{marginTop: '30px'}}>{posts?.story?.author}</p>
              <div className="book-tags">
                <span 
                  style={{
                    position: 'absolute',
                    top: '6px',
                    right: '2px',
                  }}
                className="book-tag">{`Chapter ${index}`}</span>
                <span 
                  style={{
                    position: 'absolute',
                    bottom: '6px',
                    right: '2px',
                  }}
                  className="book-tag">NONAME</span>
              </div>
            </div>
            </div>
        </div>
        <div className="left">
            <h3>{posts?.story?.title }</h3>
          <p className="synopsis">{posts?.story?.description?.slice(0, 200)}</p>
        </div>
    </div>
    );
  };

const ProductDetail = ({posts, index}) => {

  function extractRatingInfo(str) {
    const regex = /Đánh giá:\s(\d+\.\d+)\/10\s+từ\s+(\d+)\s+lượt/;
    const match = str?.match(regex);

    if (match) {
        return {
            rating: parseFloat(match[1]),  // Trả về giá trị điểm đánh giá dưới dạng số thực
            votes: parseInt(match[2], 10)  // Trả về số lượt dưới dạng số nguyên
        };
    } else {
        return null;  // Nếu không tìm thấy thông tin, trả về null
    }
}

const rating = extractRatingInfo(posts?.story?.rating)?.rating/2;
const validRating = isNaN(rating) || rating < 0 ? 0 : rating;

  // Làm tròn xuống cho số ngôi sao đầy
  const fullStars = Math.floor(validRating);
  // Kiểm tra nếu có phần thập phân thì thêm 1 nửa ngôi sao
  const hasHalfStar = validRating % 1 !== 0;
  // Tạo mảng cho ngôi sao đầy (nếu fullStars >= 0)
  const stars = Array(fullStars).fill(0);

    return (
      <div className="product-detail">
        

        <Info posts={posts} index={index} />
        <div>
          {posts?.story?.categories?.map((genre, index) => (
              <Tag icon={<ClockCircleOutlined />} key={index} color="default">
                {genre}
              </Tag>
          ))}
        </div>
        {/* Product Meta (Rating, Pages, Reviews) */}
        <div className="product-meta">
          <div className="meta-item">
            <span>{extractRatingInfo(posts?.story?.rating)?.rating}</span>
            <small>Review</small>
          </div>
          <div className="meta-item">
            <span>
            {stars.map((_, index) => (
          <span key={index}>
            <StarFilled />
          </span> // Ngôi sao đầy
      ))}
      {hasHalfStar && <span>
        <StarTwoTone />
        </span>} {/* Ngôi sao nửa nếu có */}
            </span>
          </div>
          <div className="meta-item">
            <span>{extractRatingInfo(posts?.story?.rating)?.votes}</span>
            <small>Sold</small>
          </div>
        </div>
  
        {/* Add to Cart Button */}
          <Follow posts={posts} index={index} />
      </div>
    );
  };

  export default ProductDetail;