import React, { useEffect, useState } from 'react';
import ListComicUpdate from './component/ListComicUpdate';
import { Helmet } from 'react-helmet';
import BookList from './component/BookList';
import CategoryTabs from './component/CategoryTabs';
import SearchBar from './component/SearchBar';
import Header from './component/Header';
import Carousels from './component/Carousels';
import Books from './component/Book';
import Loading from '../../component/Loading';
import CategoryList from './component/CategoryList';
import './styles.css';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import { CallApi } from '../../api/CallApi';


const Baner = () => {
  return (
    <div className="baner-container">
      <img className="baner-image" src="/images/banner3.png" alt="baner" />
    </div>
  );
};



const Home = () => {

  // URL}/stories/hot?page=1&limit=10`); 
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/stories/`;
    const params = { page: 1, limit: 10 }; 
    try {
      CallApi(url, params).then((res) => {
        setPosts(res);
        setLoading(false);
      });
    } catch (error) {
      console.error("Failed to load posts:", error);
    }
  }, []);

  const getTotal = (e) => {
    // console.log(e.totalDocuments)
  };
  // if (loading) {
  //   return <Loading />;
  // };
    return (
        <div className="app-container" id="app-container">
          <Helmet>
            <title>Mê Truyện</title>
            <meta name="description" content="
              Mê Truyện là nền tảng online miễn phí đọc truyện chữ được convert hoặc dịch kỹ lưỡng do các converter và dịch giả đóng góp, cập nhật liên tục hàng ngày hàng giờ với đủ các thể loại tiên hiệp, kiếm hiệp, huyền ảo ...
            " />
            <meta name="keywords" content="mê truyện, tiên hiện, xuyên không, huyền ảo" />
          </Helmet>
            {/* <Header username="hyicomic" />
            <SearchBar /> */}
            {/* <CategoryTabs /> */}
            {/* <BookList />  */}

            <div>
              <Books posts={posts?.stories?.slice(3, 9)} item={4} title="TRUYỆN ĐỀ CỬ" />
              <ListComicUpdate getTotal={getTotal} link="all" title="Truyện mới cập nhật"/>
              <Baner />
              <ListComicUpdate getTotal={getTotal} link="hot" title="Truyện Hot"/>
              <Books posts={posts?.stories?.slice(0, 9)} title="TOP TRUYỆN" item={4}/>
              <ListComicUpdate getTotal={getTotal} link="new" title="Truyện New"/>
            </div>
            <div>
              {/* <CategoryList /> */}
            </div>
        </div>
    )
  };



  export default Home;