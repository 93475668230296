

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Pagination, Divider, List, Tag, Avatar } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { BrowserRouter as Router, Route, Routes, Link, useParams  } from 'react-router-dom';

import { CallApi } from '../../api/CallApi';
import Books from '../HomePage/component/Book';



const ComicPageArt = () => {


    const [data, setData] = useState([]);

    useEffect(() => {
        const url = 'https://otruyenapi.com/v1/api/home';
        try {
          CallApi(url).then((res) => {
            setData(res.data)
          });
        } catch (error) {
          console.error("Failed to load posts:", error);
        }
      }, []);
      console.log(data)

        return (
            <div>
                <List
                itemLayout="horizontal"
                dataSource={data.items}
                renderItem={(item, index) => {

                    console.log(item)
                    return(
                <List.Item>
                    <List.Item.Meta
                    avatar={<img 
                        src={`https://img.otruyenapi.com/uploads/comics/${item.thumb_url}`}
                        style={{
                            width: '60px', 
                            height: '70px', 
                            objectFit: 'cover', 
                            borderRadius: '5px', 
                            marginLeft: '10px'
                          }} 
                        />}
                    title={<a href="/comic-art">{item.name}</a>}
                    description={item.updatedAt}
                    />
                </List.Item>
                )}}
            />
                </div>
        )
    }
    
    export default ComicPageArt;