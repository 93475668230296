import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Pagination, Divider, List, Tag } from 'antd';
import { BrowserRouter as Router, Route, Routes, Link, useParams  } from 'react-router-dom';
import './styles.css'

import ListComicUpdate from '../HomePage/component/ListComicUpdate';


//
import Loading from '../../component/Loading';

const AllComicUpdate = () => {
  const { active } = useParams();

    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const [total, setTotal] = useState(0);
      const onChange = (pageNumber) => {
        setPage(pageNumber); // Cập nhật page
        setPosts([]); // Xóa dữ liệu trên state
      };

      const [visible, setVisible] = useState(true); // Trạng thái hiển thị của menu bottom
    
      useEffect(() => {
        
      }, [total]);

      const getTotal = (e) => {
        setTotal(e.totalDocuments)
      };

    return (
        <div className="all-comic-update">
                <ListComicUpdate page={page} getTotal={getTotal} page={page} link={active == 'all' ? '' : active} title={
                  active == 'all' ? 'Mới Cập Nhật' : active == 'hot' ? 'Truyện Hot' : 'Truyện New'  } />
                <div 
                  style={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    padding: 0,
                    transition: 'transform 0.3s ease, opacity 0.3s ease',
                    transform: visible ? 'translateY(0)' : 'translateY(100%)', // Hiệu ứng trượt
                    opacity: visible ? 1 : 0, // Hiệu ứng mờ
                    right: 0,
                    backgroundColor: 'white', // Đảm bảo có màu nền
                    padding: '10px 0',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Thêm chút bóng đổ để phân biệt với nội dung phía dưới
                    zIndex: 1000, // Đảm bảo nó luôn nằm trên các phần tử khác
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    height: '80px',
                  }}>
                  <Pagination showQuickJumper defaultCurrent={1} total={total} onChange={onChange} />
                </div>
            <p style={{ marginBottom: '50px' }}></p>
        </div>
    )
};

export default AllComicUpdate