import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';




const Follow = ({posts , index}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';


  // Khởi tạo kho truyện từ localStorage
  const [comics, setComics] = useState(() => {
    const storedComics = localStorage.getItem('comics');
    return storedComics ? JSON.parse(storedComics) : [];
  });
  // Khởi tạo state cho truyện mới
  const [newComic, setNewComic] = useState({ title: '', author: '' });


  // Cập nhật localStorage mỗi khi danh sách comics thay đổi
  useEffect(() => {
    localStorage.setItem('comics', JSON.stringify(comics));
  }, [comics]);


   // Hàm để thêm truyện mới vào kho
   const openMessage = () => {
      messageApi.open({
        key,
        type: 'loading',
        content: 'Đang thêm...',
      });
    console.log(posts.story.title);
    const check = comics.some((comic) => comic.title === posts.story.title);
    if (!check) {
      setComics([...comics, { ...posts.story, ...{chapter:`Chương ${index}`} }]);  // Thêm truyện mới vào danh sách comics
      setNewComic({ title: '', author: '' });  // Xóa các trường nhập sau khi thêm
      setTimeout(() => {
        messageApi.open({
          key,
          type: 'success',
          content: 'Đã thêm truyện!',
          duration: 2,
        });
      }, 1000);
    }else{
      setTimeout(() => {
        messageApi.open({
          key,
          type: 'error',
          content: 'Truyện đã có trong danh sách!',
          duration: 2,
        });
      }, 1000);
    }
 
  };

  return (
    <>
      {contextHolder}
      <Button className="add-to-cart" type="primary" onClick={openMessage}>
        Lưu Truyện
      </Button>
    </>
  );
};
export default Follow;