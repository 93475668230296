


import axios from 'axios';

const CallApi = async (url, params = {}) => {
  try {
    const response = await axios.get(url, { params }); 
    return response.data; 
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; 
  }
};

export { CallApi };