import React, { useEffect, useState } from 'react';
import 'antd/dist/reset.css'; // Import CSS của Ant Design
import './App.css'; // Import file CSS tùy chỉnh

import { BrowserRouter as Router, Route, Routes, Link, useParams  } from 'react-router-dom';

import { CallApi } from './api/CallApi';


import ComicPage from './page/ComicPage';
import ChapterPage from './page/ChapterPage';
import AllComicUpdate from './page/AllComicUpdate';
import LayoutHome from './LayoutHome';
import MenuFload from './component/Loading/MenuFload';
import Search from './component/Search';
import SearchComicPage from './page/SearchComicPage';
import ComicStorePage from './page/ComicStorePage'

import ComicPageArt from './page/ComicPageArt'
import Loading from './component/Loading';

import MenuShow from './component/MenuShow'


        //get data
       

function App() {

  const [posts, setPosts] = useState([]); // Khởi tạo state để lưu dữ liệu lấy về
  const [loading, setLoading] = useState(true); // State để theo dõi trạng thái loading
  const [error, setError] = useState(null); // State để lưu lỗi nếu có
  const [page, setPage] = useState(1);


  // useEffect(() => {
  // const url = `${process.env.REACT_APP_API_URL}/stories/`;
  // try {
  //      CallApi(url).then((res) => {
  //          setPosts([]);
  //         setPosts(res);
  //         setLoading(false);
  //     });
  // } catch (error) {
  //     console.error("Failed to load posts:", error);
  //     setLoading(false);
  //     setError(error);
  // }
  // }, [ page]);
  // if (loading) {
  //   return <Loading />;
  // };

  return (
    <Router>
      <div>
      <div>
        <div className='logo' style={{display: 'flex'}}>
          {/* <img src='https://cdn.mobilecity.vn/mobilecity-vn/images/2023/09/truyen-tranh-tu-tien-nguyen-ton-1.jpg.webp' className='logo-image'/> */}
          <div className='logo-text'>@nguyenthanhtuan</div>
        </div>
        <Search />
      </div>
        <Routes>
          <Route path="/" element={<LayoutHome />} />
          <Route path="/truyen/:id/:index" element={<ComicPage />} />
          <Route path="/truyen/:id/:chapter/:index" element={<ChapterPage />} />
          <Route path="/all-comic-update/:active" element={<AllComicUpdate />} />
          <Route path="/comic/search/:query" element={<SearchComicPage />} />
          <Route path="/comic/stores" element={<ComicStorePage />} />

          <Route path="/comic-art" element={<ComicPageArt />} />
        </Routes>
        <MenuFload />
       
      </div>
    </Router>
  )
}

export default App;
