import React, { useEffect, useState } from 'react';
import { Divider, List, Tooltip, Button, Tag } from 'antd';
import { SendOutlined, SignatureOutlined } from '@ant-design/icons';
import { BrowserRouter as Router, Route, Routes, Link, useParams, useNavigate  } from 'react-router-dom';

import { CallApi } from '../../../api/CallApi';
import images from '../../../acess/dataImage';

const ListComicUpdate = ({link, title, getTotal, page}) => {


// URL}/stories/hot?page=1&limit=10`); 
  const [posts, setPosts] = useState([]);
  const { active } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/stories/${link == 'all' ? '' : link}?page=${page}&limit=10`;
    const params = { page: 1, limit: 10 }; 
    try {
      CallApi(url, params).then((res) => {
        setPosts(res);
        getTotal(res)
      });
    } catch (error) {
      console.error("Failed to load posts:", error);
    }
  }, [page]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]); // Chỉ chạy một lần khi component mount



  function extractChapterNumber(text) {
    const match = text?.match(/\d+/); // Tìm số trong chuỗi
    return match ? parseInt(match[0], 10) : null; // Trả về số chương hoặc null nếu không tìm thấy
  }

  // <Link 
  // key={index} 
  // to={`/comic/${extractChapterNumber(item.chapter)}${item.link.replace(process.env.REACT_APP_API_URL_COMIC, '')}`}>
  function timeAgo(dateString) {
    const pastDate = new Date(dateString);
    const now = new Date();
    const differenceInMilliseconds = now - pastDate;
    const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    const differenceInHours = differenceInMinutes / 60;
    if (differenceInHours >= 12) {
        const days = Math.floor(differenceInHours / 12); // Mỗi 12 giờ là một ngày
        const remainingMinutesAfterDays = differenceInMinutes % (12 * 60); // Tính phần còn lại sau khi chia thành ngày
        const hours = Math.floor(remainingMinutesAfterDays / 60);
        const minutes = remainingMinutesAfterDays % 60;
        return `${days} ngày trước`;
    }
    if (differenceInMinutes >= 60) {
        const hours = Math.floor(differenceInMinutes / 60);
        const minutes = differenceInMinutes % 60;
        return `${hours} giờ trước`;
    }
    return `${differenceInMinutes} phút trước`;
}
  const goToChapter = (item) => {
    navigate(`/truyen/${item.name}/${extractChapterNumber(item.chapter) || 0}`, { state: item });
  };

  console.log(posts)

  return(
  <div>
    <Divider 
      style={{
        marginBottom: '10px',
        marginTop: '10px'}}
      orientation="left">{title?.toUpperCase()}</Divider>
    <List
      itemLayout="horizontal"
      dataSource={active == undefined ? posts.stories?.slice(0,5) : posts.stories}
      renderItem={(item, index) => {
        return(
        <div 
          key={index} 
          onClick={() => goToChapter(item)}>
        <div key={item.title}
          style={{
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            marginBottom: '20px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            }}
        >
        <List.Item>
          <List.Item.Meta
            avatar={<img src={images[Math.floor(Math.random() * images.length)]} 
              style={{
                width: '65px', 
                height: '90px', 
                objectFit: 'cover', 
                borderRadius: '5px', 
                marginLeft: '10px'
              }}/>}
            title={
                <p style={{marginBottom: 0, fontWeight: '600'}}>{item.title}&nbsp;
                <Tag 
                  color={
                    item.active == 'hot' ? 'red' : 
                    item.active == 'new' ? 'green' : 
                    item.active == 'noname' ? 'yellow' : 'blue'}
                  bordered={false}>{
                    item.active == 'noname' ? 'UP' : 
                    item.active?.toUpperCase()}
                </Tag>
                </p>
             }
            description={
            <div key={index}>
              <div style={{marginBottom: '5px',   marginLeft:'5px'}}>
                <span>Tác giả: {item.author}</span> 
              </div>
              <div>
                <Tag style={{color: '#000'}} color="rgb(241 236 231)" bordered={false}>{item.chapter == '' ? 'Chương 0' : item.chapter}</Tag>
                
                {title == 'Truyện mới cập nhật' ? <Tag color="orange" bordered={false}>{timeAgo(item.lastUpdated) || '5 phút trước'}</Tag> : null}
                
                </div>
            </div>}
          />
        </List.Item> 
        </div>
        </div>
      )}}
    />
    {
      active == undefined ?  
      <Divider orientation="right">
          <Button color="default" variant="link" icon={<SendOutlined />}>
            <Link to={`/all-comic-update/${title == 'Truyện mới cập nhật' ? 'all' : title == 'Truyện Hot' ? 'hot' : 'new'}`} >Xem thêm</Link>
          </Button>
      </Divider> : null
    }
  </div>
)};
export default ListComicUpdate;