import React from 'react';
import { Divider, List, Typography } from 'antd';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate   } from 'react-router-dom';

const ListChap = ({posts, total, index, name}) => {
  const navigate = useNavigate();

  const goToUser = (chap) => {
    // const url = `${process.env.REACT_APP_API_URL}/chapter?url=${process.env.REACT_APP_API_URL_COMIC}/${id}/chuong-${dataFromChild}/`;
    // Điều hướng đến trang User và truyền dữ liệu state
    navigate(`/truyen/${name}/chuong-${chap}/${index}`, { state: { index } });
  };


  return(
  <>
    <Divider orientation="left">{total == undefined ? 'Không có truyện' : 'Danh sách truyện'}</Divider>
    {/* <List
      size="large"
      footer={<div style={{ textAlign: 'center', color: '#333' }}>metruyen</div>}
      bordered
      dataSource={Array(index).keys()}
      renderItem={(item, index) => 
        <List.Item>
            <p onClick={()=> goToUser(item)}>{item + 1}</p>
        </List.Item>}
    /> */}
    <List size="large" footer={<div style={{ textAlign: 'center', color: '#333' }}>metruyen</div>}>
     {[...Array(index).keys()].map((x) => (
              <List.Item>
               <p onClick={() => goToUser(x+1)}>Chương {`${x+1}`}</p>
               </List.Item>
          ))}
          </List>
  </>
)};
export default ListChap;
