


import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';

import images from '../../../acess/dataImage';
import {Divider} from 'antd';


const Books = ( {story, index}) => {
  const navigate = useNavigate();

  function extractChapterNumber(text) {
    const match = text?.match(/\d+/); // Tìm số trong chuỗi
    return match ? parseInt(match[0], 10) : null; // Trả về số chương hoặc null nếu không tìm thấy
  }


  const allChap = extractChapterNumber(story?.chapter);

  const goToChapter = () => {
    navigate(`/truyen/${story?.name}/${allChap || 0}`, { state: story });
  };

  
    return(
    <div className="book-card">
      <div key={index} onClick={()=> goToChapter()}>
      <img src={images[Math.floor(Math.random() * images.length)]} 
      alt={story?.title} className="book-cover" />
      <div className="book-info">
        <h3>爱情事务</h3>
        <p style={{marginTop: '5px', fontSize: '10px', width:'90px', paddingTop:'19px'}}>{story?.title}</p>
        <p style={{marginTop: '30px'}}>{story?.author}</p>
        <div className="book-tags">
          <span 
            style={{
              position: 'absolute',
              top: '6px',
              right: '2px',
            }}
           className="book-tag">{story?.chapter == '' ? ' Chapter 0' : story?.chapter?.toUpperCase()}</span>
          <span 
            style={{
              position: 'absolute',
              bottom: '6px',
              right: '2px',
            }}
            className="book-tag">{story?.tags?.toUpperCase()}</span>
        </div>
      </div>
      </div>
    </div>
  )};
  
  const Book = ({posts, title, items}) => {
    return (
      <div className="book-list">
        <Divider orientation="left">{title}</Divider>
        <div className='book-flex'>
        <div className="book-grid">
          {posts?.map((book, index) => {
            return(
            <Books story={book} key={index} index={index} />
          )})}
  
        </div>
        </div>
      </div>
    );
  };

export default Book;