import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {

    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        if (searchQuery.trim()) {
          // Điều hướng đến trang tìm kiếm với query
          navigate(`/comic/search/${searchQuery}`);
        }
      }
    };
  return (
    <div className="search-container">
      <div className="search-icon"><SearchOutlined /></div>
      <input 
        type="text"
        placeholder="Tiềm Kiếm..."
        className="search-input"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyPress={handleKeyPress} 
      />
    </div>
  );
};

export default SearchBar;
