

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams  } from 'react-router-dom';
import { Pagination, Divider, List, Tag } from 'antd';



import Books from '../HomePage/component/Book';

import { CallApi } from '../../api/CallApi';

const SearchComicPage = () => {

    const { query } = useParams(); 

        //get data
        const [posts, setPosts] = useState([]); // Khởi tạo state để lưu dữ liệu lấy về
        const [loading, setLoading] = useState(true); // State để theo dõi trạng thái loading
        const [error, setError] = useState(null); // State để lưu lỗi nếu có
        const [page, setPage] = useState(1);


        useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}/comic/search?query=${query}&page=$${page}&limit=10`;
        try {
             CallApi(url).then((res) => {
                 setPosts([]);
                setPosts(res);
                setLoading(false);
            });
        } catch (error) {
            console.error("Failed to load posts:", error);
            setLoading(false);
            setError(error);
        }
        }, [query, page]);

            const onChange = (pageNumber) => {
                setPage(pageNumber); // Cập nhật page
                setPosts([]); // Xóa dữ liệu trên state
              };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Books posts={posts?.stories} />
                
            </div>
            <Pagination showQuickJumper defaultCurrent={1} total={100} onChange={onChange} />
        </div>
       
    );
    };
    
    export default SearchComicPage;