import React, { useEffect, useState } from 'react';
import { CustomerServiceOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';

const TextToSpeech = ({ text }) => {
  const [voices, setVoices] = useState([]);
  const [status, setStatus] = useState(0);


  // Lấy danh sách giọng đọc khi component được mount
  useEffect(() => {
    const fetchVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
    };

    fetchVoices();
    window.speechSynthesis.onvoiceschanged = fetchVoices; // Cập nhật khi giọng đọc thay đổi
  }, []);

  const speak = () => {
    if ('speechSynthesis' in window) {
      const speech = new SpeechSynthesisUtterance(text);
      speech.lang = 'vi-VN'; // Đặt ngôn ngữ thành tiếng Việt
      speech.rate = 1; // Tốc độ đọc
      speech.pitch = 1; // Cao độ giọng đọc
      setStatus(1);

      // Tìm giọng đọc tiếng Việt
      const voice = voices.find(v => v.lang === 'vi-VN' || v.name.includes('Vietnam'));
        speech.voice = voice; // Đặt giọng đọc tiếng Việt
   

      window.speechSynthesis.speak(speech); // Đọc văn bản
    } else {
      alert('Trình duyệt của bạn không hỗ trợ đọc văn bản');
    }
  };

  const stop = () => {
    window.speechSynthesis.cancel(); // Ngừng đọc
    setStatus(0);
  };

  return (
    
      status === 0 ? (
        <FloatButton
          onClick={speak}
          shape="circle"
          style={{
            left: 50,
            opacity: 0.8,
            backgroundColor: '#1890ff !important',
            insetInlineEnd: 94,
            bottom: 110,
          }}
          icon={<CustomerServiceOutlined />}
        />
      ) : (
        <FloatButton
          onClick={stop}
          shape="circle"
          style={{
            left: 50,
            opacity: 0.8,
            backgroundColor: '#1890ff !important',
            insetInlineEnd: 94,
            bottom: 110,
          }}
          icon={<PauseCircleOutlined />}
        />
      )
    
  );
};

export default TextToSpeech;
